<template>
  <div v-loading="isLoading">
    <div v-if="!selectedProject" class="content">
      <h5>No Project</h5>
    </div>
    <ul v-else>
      <li v-for="(value, key) in selectedProject" :key="key">
        <div v-if="keyToShow.includes(key) && value" class="contact-section">
          <div class="section-title">
            <span class="has-text-weight-bold">{{ $t(`project.${key}`) }}</span>
          </div>
          <div class="section-content">
            <p>{{ value }}</p>
          </div>
        </div>
      </li>
      <li v-if="selectedProject.start_date">
        <div class="contact-section">
          <div class="section-title">
            <span class="has-text-weight-bold">{{ $t(`project.start_date`) }}</span>
          </div>
          <div class="section-content">
            <p>{{ $moment(selectedProject.start_date).format('YYYY/MM/DD') }}</p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>


<script>
export default {
  data() {
    return {
      isLoading: false,
      keyToShow: ['client', 'contractor', 'instrument_company', 'location', 'name', 'project_id'],
    };
  },
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
  },
};
</script>

<style lang="stylus" scoped>
.rotate-quarter
  transform rotate(0.25turn)
.seperator
  margin 0 0.5rem
.contact-section
  .section-title
    .section-icon
      margin-right 1rem
  .section-content
    padding 1rem 2rem 2rem
</style>